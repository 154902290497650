<template>
  <div>
    <!-- แถบแรก -->
    <b-card no-body>
      <div class="card-content">
        <b-card-body>
          <h4 class="card-title">
            ข้อมูลระบบทั่วไป
          </h4>
          <hr />
          <div class="mt-3" />
          <b-row>
            <b-col cols="md-4">
              <b-form-group>
                  <label class="control-label">ชื่อเว็ป</label>
                  <input type="text" v-model="result.web_name" class="form-control"  placeholder="ชื่อเว็ป">
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                  <label class="control-label">Line ID</label>
                  <input type="text" v-model="result.line_id" class="form-control"  placeholder="ข้อมูล Line ID">
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                  <label class="control-label">Line Url</label>
                  <input type="text" v-model="result.line_url" class="form-control"  placeholder="ข้อมูล Line Url">
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="md-4">
              <b-form-group>
                  <label class="control-label">Web Title</label>
                  <input type="text" v-model="result.web_title" class="form-control"  placeholder="ข้อมูล Web Title">
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                  <label class="control-label">Web description</label>
                  <input type="text" v-model="result.web_description" class="form-control"  placeholder="ข้อมูล Web description">
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                  <label class="control-label">Web keyword</label>
                  <input type="text" v-model="result.web_keyword" class="form-control"  placeholder="ข้อมูล Web keyword">
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="md-4">
              <b-form-group>
                  <label class="control-label">Logo</label>
                  <input type="text" v-model="result.web_logo" class="form-control"  placeholder="ข้อมูล Logo">
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                  <label class="control-label">เบอร์โทรศัพท์</label>
                  <input type="number" v-model="result.telephone_number" class="form-control"  placeholder="ข้อมูลเบอร์โทรศัพท์">
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                  <label class="control-label">เวลาตัดยอด (ทุก 24 ชั่วโมง)</label>
                  <flat-pickr
                    v-model="result.calculate_time"
                    class="form-control"
                    :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',}"
                  />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">สถานะ Discord webhook</label>
                <b-form-select v-model="result.discord_webhook_status" :options="option" />
              </b-form-group>
            </b-col>
            <b-col cols="md-8">
              <b-form-group v-if="result.discord_webhook_status == 1">
                <label class="control-label">Discord webhook token</label>
                <textarea type="text" v-model="result.discord_webhook_token" class="form-control"  placeholder="ข้อมูล Discord webhook token"></textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">สถานะ Line notify</label>
                <b-form-select v-model="result.line_notify_status" :options="option" />
              </b-form-group>
            </b-col>
            <b-col cols="md-8">
              <b-form-group v-if="result.line_notify_status == 1">
                <label class="control-label">Line notify token</label>
                <textarea type="text" v-model="result.line_notify_token" class="form-control"  placeholder="ข้อมูล Line notify token"></textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="md-4">
              <b-form-group>
                  <label class="control-label">สถานะ Line notify Log API</label>
                  <b-form-select v-model="result.line_notify_log_api_status" :options="option" />
              </b-form-group>
            </b-col>
            <b-col cols="md-8">
              <b-form-group v-if="result.line_notify_log_api_status == 1">
                  <label class="control-label">Line notify token Log API</label>
                  <textarea type="text" v-model="result.line_notify_log_api_token" class="form-control"  placeholder="ข้อมูล Line notify Log API token"></textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col cols="md-12">
              <div class="text-right m-b-10">
                <div class="text-right m-b-10">
                  <router-link :to="'/'" class="btn bg-gradient-warning waves-effect waves-light mr-1"><span><feather-icon icon="ArrowLeftIcon" class="font-medium-1 mr-1" /></span>ย้อนกลับ</router-link>
                  <button @click="update()" type="submit" class="btn bg-gradient-success waves-effect waves-light text-white"><span><feather-icon icon="SaveIcon" class="font-medium-1 mr-1" /></span>แก้ไข</button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </div>
    </b-card>
    <!-- สิ้นสุดแถบแรก -->
    <b-card no-body>
      <div class="card-content">
        <b-card-body>
          <h4 class="card-title">
            ข้อมูลออโต้สร้างยูสเซอร์
          </h4>
          <hr>
          <div class="mt-3" />
          <b-row>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">รูปแบบ</label>
                <b-form-select v-model="result.auto_create_member" :options="option_register" />
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">จำนวนเงินฝากครั้งแรก</label>
                <input type="number" v-model="result.auto_create_member_deposit_amount" class="form-control" placeholder="กรอกเฉพาะตัวเลขเท่านั้น">
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">สถานะ แจ้งเตือนสมาชิกใหม่</label>
                <b-form-select v-model="result.auto_create_member_status" :options="option" />
              </b-form-group>
            </b-col>
            <b-col cols="md-8">
              <b-form-group v-if="result.auto_create_member_status == 1">
                  <label class="control-label">ข้อความแจ้งเตือนสมาชิกใหม่</label>
                  <b-input-group-prepend>
                    <button @click="toggleMessage('auto_create_member_status_text', '$username')" class="btn btn-sm ml-1 mb-1 bg-gradient-primary">ชื่อยูสเซอร์</button>
                    <button @click="toggleMessage('auto_create_member_status_text', '$datetime')" class="btn btn-sm ml-1 mb-1 bg-gradient-info">วันเวลา</button>
                    <button @click="toggleMessage('auto_create_member_status_text', '$number')" class="btn btn-sm ml-1 mb-1 bg-gradient-warning">ลำดับ</button>
                    <button @click="toggleMessage('auto_create_member_status_text', '$referer')" class="btn btn-sm ml-1 mb-1 bg-gradient-success">แนะนำโดย</button>
                  </b-input-group-prepend>
                  <textarea type="text" v-model="result.auto_create_member_status_text" class="form-control"  placeholder="ข้อความแจ้งเตือนสมาชิกใหม่"></textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col cols="md-12">
              <div class="text-right m-b-10">
                <div class="text-right m-b-10">
                  <router-link :to="'/'" class="btn bg-gradient-warning waves-effect waves-light mr-1"><span><feather-icon icon="ArrowLeftIcon" class="font-medium-1 mr-1" /></span>ย้อนกลับ</router-link>
                  <button @click="update()" type="submit" class="btn bg-gradient-success waves-effect waves-light text-white"><span><feather-icon icon="SaveIcon" class="font-medium-1 mr-1" /></span>แก้ไข</button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </div>
    </b-card>
    <!-- สิ้นสุดแถบ การสมัคร -->
    <b-card no-body>
      <div class="card-content">
        <b-card-body>
          <h4 class="card-title">
            ข้อมูลระบบถอนเงิน
          </h4>
          <hr>
          <div class="mt-3" />
          <b-row>
            <b-col cols="md-4">
              <b-form-group>
                  <label class="control-label">Clear Turn</label>
                  <input type="number" v-model="result.clear_turn" class="form-control"  placeholder="กรอกเฉพาะตัวเลขเท่านั้น">
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                  <label class="control-label">ยอดถอนเงินขั้นต่ำ (บาท)</label>
                  <input type="number" v-model="result.withdraw_min_amount" class="form-control"  placeholder="กรอกเฉพาะตัวเลขเท่านั้น">
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="md-4">
              <b-form-group>
                  <label class="control-label">สถานะ ตรวจสอบยอดถอน</label>
                <b-form-select v-model="result.withdraw_verify_status" :options="option" />
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                  <label class="control-label">เวลาตรวจสอบยอดถอน (ทุก 24 ชั่วโมง)</label>
                  <flat-pickr
                    v-model="result.withdraw_verify_time"
                    class="form-control"
                    :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',}"
                  />
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">สถานะ แจ้งถอนเงิน</label>
                <b-form-select v-model="result.withdraw_member_status" :options="option" />
              </b-form-group>
            </b-col>
            <b-col cols="md-8">
              <b-form-group v-if="result.withdraw_member_status == 1">
                  <label class="control-label">ข้อความแจ้งถอนเงิน</label>
                  <b-input-group-prepend>
                    <button @click="toggleMessage('withdraw_member_status_text', '$username')" class="btn btn-sm ml-1 mb-1 bg-gradient-primary">ชื่อยูสเซอร์</button>
                    <button @click="toggleMessage('withdraw_member_status_text', '$datetime')" class="btn btn-sm ml-1 mb-1 bg-gradient-info">วันเวลา</button>
                    <button @click="toggleMessage('withdraw_member_status_text', '$amount')" class="btn btn-sm ml-1 mb-1 bg-gradient-danger">ยอดถอน</button>
                    <button @click="toggleMessage('withdraw_member_status_text', '$current')" class="btn btn-sm ml-1 mb-1 bg-gradient-warning">ยอดก่อนถอน</button>
                    <button @click="toggleMessage('withdraw_member_status_text', '$balance')" class="btn btn-sm ml-1 mb-1 bg-gradient-success">ยอดคงเหลือ</button>
                  </b-input-group-prepend>
                  <textarea type="text" v-model="result.withdraw_member_status_text" class="form-control"  placeholder="ข้อความแจ้งถอนเงิน"></textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col cols="md-12">
              <div class="text-right m-b-10">
                <div class="text-right m-b-10">
                  <router-link :to="'/'" class="btn bg-gradient-warning waves-effect waves-light mr-1"><span><feather-icon icon="ArrowLeftIcon" class="font-medium-1 mr-1" /></span>ย้อนกลับ</router-link>
                  <button @click="update()" type="submit" class="btn bg-gradient-success waves-effect waves-light text-white"><span><feather-icon icon="SaveIcon" class="font-medium-1 mr-1" /></span>แก้ไข</button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </div>
    </b-card>
    <!-- สิ้นสุดแถบ การถอน -->
    <!-- สิ้นสุด -->
    <b-card no-body>
      <div class="card-content">
        <b-card-body>
          <h4 class="card-title">
            ตั้งค่าวงล้อนำโชค
          </h4>
          <hr>
          <b-row>
            <b-col cols="md-2">
              <b-form-group>
                <label class="control-label">สถานะ วงล้อนำโชค</label>
                <b-form-select v-model="result.wheel_status" :options="option" />
              </b-form-group>
            </b-col>
            <b-col cols="md-2" v-if="result.wheel_status == '1'">
              <b-form-group>
                <label class="control-label">เติมเงินขั้นต่ำรับสิทธิ์/วัน</label>
                <input type="number" v-model="result.wheel_min_amount" class="form-control" placeholder="กรอกเฉพาะตัวเลขเท่านั้น">
              </b-form-group>
            </b-col>
            <b-col cols="md-8" v-if="result.wheel_status == '1'">
              <b-form-group>
                <label class="control-label">รูปภาพโปรวงล้อนำโชค</label>
                <input v-model="result.wheel_banner" class="form-control" placeholder="ลิ้งรูปภาพโปรวงล้อนำโชค">
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" v-if="result.wheel_status == '1'">
              <b-table hover responsive :fields="fields" :items="items" show-empty>
                <template #empty="">
                  <div class="align-items-center text-center">ไม่พบข้อมูล</div>
                </template>
                <template #emptyfiltered="">
                  <div class="align-items-center text-center">ไม่พบข้อมูล</div>
                </template>
                <template #cell(num)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(type)="data">
                  <b-button v-if="data.item.type == '1'" @click="data.item.type = '2'" variant="btn-primary" class="btn bg-gradient-success waves-effect waves-light" style="width: 100px">
                    อนุญาต
                  </b-button>
                  <b-button v-else @click="data.item.type = '1'" variant="btn-primary" class="btn bg-gradient-danger waves-effect waves-light" style="width: 100px">
                    ไม่อนุญาต
                  </b-button>
                </template>
                <template #cell(setting)="data">
                  <div class="btn-group">
                    <b-dropdown variant="link" toggle-class="bg-gradient-success waves-effect waves-light">
                      <template #button-content>
                        <span><feather-icon icon="EditIcon" class="font-medium-1 mr-1" /></span>ตั้งค่า
                      </template>
                      <b-dropdown-item @click="modal_wheel(data.item)" v-b-modal.modal-wheel><span><feather-icon icon="EditIcon" class="text-warning mr-1" /></span>แก้ไข</b-dropdown-item>
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item :data-id="data.item.id"><span><feather-icon icon="TrashIcon" class="text-danger mr-1" /></span>ลบ</b-dropdown-item>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col cols="md-12">
              <div class="text-right m-b-10">
                <div class="text-right m-b-10">
                  <router-link :to="'/'" class="btn bg-gradient-warning waves-effect waves-light mr-1"><span><feather-icon icon="ArrowLeftIcon" class="font-medium-1 mr-1" /></span>ย้อนกลับ</router-link>
                  <button @click="update()" type="submit" class="btn bg-gradient-success waves-effect waves-light text-white"><span><feather-icon icon="SaveIcon" class="font-medium-1 mr-1" /></span>แก้ไข</button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </div>
    </b-card>
    <!-- สิ้นสุด wheel -->
    <b-card no-body>
      <div class="card-content">
        <b-card-body>
          <h4 class="card-title">
            ตั้งค่าสะสมแต้ม
          </h4>
          <hr>
          <b-row>
            <b-col cols="md-2">
              <b-form-group>
                <label class="control-label">สถานะ สะสมแต้ม</label>
                <b-form-select v-model="result.reward_status" :options="option" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="result.reward_status == '1'">
            <b-col md="3" sm="2" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">แสดง</label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" v-on:change="onPageSelect" style="width: 60px"/>
                <label class="d-inline-block text-sm-right ml-50">รายการ</label>
              </b-form-group>
            </b-col>
            <b-col md="5" sm="6" class="my-1" />
            <b-col md="4" sm="4" class="my-1">
              <b-form-group label="ค้นหา :" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0" >
                <b-input-group size="sm">
                  <b-form-input id="filterInput" v-model="filter" v-on:change="onPageSelect" type="search" />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"> ล้าง </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="result.reward_status == '1'">
            <b-col cols="12">
              <b-table hover responsive :fields="fields_reward" :items="items_reward" show-empty>
                <template #empty="">
                  <div class="align-items-center text-center">ไม่พบข้อมูล</div>
                </template>
                <template #emptyfiltered="">
                  <div class="align-items-center text-center">ไม่พบข้อมูล</div>
                </template>
                <template #cell(image_url)="data">
                  <ul class="users-list align-items-center m-0 p-0">
                    <li
                      data-toggle="tooltip"
                      data-popup="tooltip-custom"
                      data-placement="bottom"
                      class="avatar pull-up m-0"
                    >
                      <img
                        class="media-object rounded"
                        :src="data.value"
                        alt="IMAgE"
                        height="140"
                        width="140"
                      >
                    </li>
                  </ul>
                </template>
                <template #cell(num)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(price)="data">
                  {{ toMoney(data.value) }}
                </template>
                <template #cell(item_qty)="data">
                  <b-row class="mb-1">
                    <b-col cols="12">
                      <span class="badge badge-info">
                        จำนวนที่มี
                      </span>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col cols="12">
                      {{ data.item.item_qty }}
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col cols="12">
                      <span class="badge badge-warning">
                        แลกไปแล้ว
                      </span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      {{ data.item.exchange_qty }}
                    </b-col>
                  </b-row>
                </template>
                <template #cell(point)="data">
                  <b-row class="mb-1">
                    <b-col cols="12">
                      <span class="badge badge-success">
                        แต้มที่ใช้
                      </span>
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col cols="12">
                      {{ toMoney(data.item.point) }}
                    </b-col>
                  </b-row>
                  <b-row class="mb-1">
                    <b-col cols="12">
                      <span class="badge badge-danger">
                        แต้มที่ลด
                      </span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      {{ toMoney(data.item.point_discount) }}
                    </b-col>
                  </b-row>
                </template>
                <template #cell(manage)="data">
                  <div class="btn-group">
                    <b-dropdown variant="link" toggle-class="bg-gradient-success waves-effect waves-light">
                      <template #button-content>
                        <span><feather-icon icon="EditIcon" class="font-medium-1 mr-1" /></span>จัดการ
                      </template>
                      <b-dropdown-item @click="modal_reward(data.item)" v-b-modal.modal-reward><span><feather-icon icon="EditIcon" class="text-warning mr-1" /></span>แก้ไข</b-dropdown-item>
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item @click="deleteReward(data.item)"><span><feather-icon icon="TrashIcon" class="text-danger mr-1" /></span>ลบ</b-dropdown-item>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row v-if="result.reward_status == '1'">
            <b-col sm="12" md="5">
              <div role="status" aria-live="polite">แสดง {{ func.show_page(perPage, currentPage) }} ถึง {{ func.show_page_to(perPage, currentPage, recordsTotal) }} จาก {{func.toNum(totalRows)}} รายการ (ค้นหา จากทั้งหมด {{perPage}} รายการ)</div>
            </b-col>
            <b-col sm="12" md="7">
              <b-pagination
                v-on:change="onPageChange"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="end"
                size="sm"
                class="my-0"
                :first-number="true"
                :last-number="true"
              >
                <template #prev-text>‹ ย้อนกลับ</template>
                <template #next-text>ถัดไป ›</template>
              </b-pagination>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col cols="md-12">
              <div class="text-right m-b-10">
                <div class="text-right m-b-10">
                  <router-link :to="'/'" class="btn bg-gradient-warning waves-effect waves-light mr-1"><span><feather-icon icon="ArrowLeftIcon" class="font-medium-1 mr-1" /></span>ย้อนกลับ</router-link>
                  <button @click="update()" type="submit" class="btn bg-gradient-success waves-effect waves-light text-white"><span><feather-icon icon="SaveIcon" class="font-medium-1 mr-1" /></span>แก้ไข</button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </div>
    </b-card>
    <!-- สิ้นสุด reward -->
    <b-modal
      id="modal-reward"
      centered
      title="สะสมแต้ม"
      ok-only
      ok-title="ตกลง"
      ok-variant=" bg-gradient-primary waves-effect waves-light"
      @ok="updateReward()"
    >
      <b-media
        class="my-1"
        no-body
      >
          <b-avatar
            :src="reward_item.image_url"
            rounded
            variant=""
            class="mx-auto w-auto h-100"
          />
      </b-media>
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="รูปภาพ:"
        label-for="reward-image_url"
      >
        <b-form-input
          id="reward-image_url"
          v-model="reward_item.image_url"
          placeholder="ลิ้งค์รูปภาพ"
        />
      </b-form-group>
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="ชื่อสินค้า:"
        label-for="reward-name"
      >
        <b-form-input
          id="reward-name"
          v-model="reward_item.name"
          placeholder="ชื่อสินค้า"
        />
      </b-form-group>
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="หมวดหมู่:"
        label-for="reward-category_name"
      >
        <b-form-input
          id="reward-category_name"
          v-model="reward_item.category_name"
          placeholder="หมวดหมู่"
        />
      </b-form-group>
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="ราคาสินค้า:"
        label-for="reward-price"
      >
        <b-form-input
          id="reward-price"
          v-model="reward_item.price"
          placeholder="ราคาสินค้า"
          type="number"
        />
      </b-form-group>
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="ราคาแต้ม:"
        label-for="reward-percent"
      >
        <b-form-input
          id="reward-percent"
          v-model="reward_item.point"
          placeholder="ราคาแต้ม"
          type="number"
        />
      </b-form-group>
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="ราคาแต้มที่ลด:"
        label-for="reward-percent"
      >
        <b-form-input
          id="reward-percent"
          v-model="reward_item.point_discount"
          placeholder="ราคาแต้มที่ลด"
          type="number"
        />
      </b-form-group>
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="จำนวนสินค้า:"
        label-for="reward-item_qty"
      >
        <b-form-input
          id="reward-item_qty"
          v-model="reward_item.item_qty"
          placeholder="จำนวนสินค้า"
          type="number"
        />
      </b-form-group>
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="แสดงสินค้า:"
        label-for="reward-prefix"
      >
        <b-button 
          variant="btn-primary" 
          :class="{ 'bg-gradient-success': reward_item.prefix == 'ADMIN', 'bg-gradient-danger': reward_item.prefix != 'ADMIN' }" 
          class="btn waves-effect waves-light" 
          style="width: 120px"
          @click="toggleRewardType()"
        >
          {{ reward_item.prefix == 'ADMIN' ? 'แสดง' : 'ไม่แสดง' }}
        </b-button>
      </b-form-group>
    </b-modal>
    <!-- สิ้นสุด reward -->
    <b-modal
      id="modal-wheel"
      centered
      title="วงล้อน้ำโชค"
      ok-only
      ok-title="ตกลง"
      ok-variant=" bg-gradient-primary waves-effect waves-light"
    >
      <b-media
        class="my-1"
        no-body
      >
          <b-avatar
            :src="wheel_item.img"
            rounded
            variant=""
            class="mx-auto w-auto h-100"
          />
      </b-media>
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="รูปภาพ:"
        label-for="input-img"
      >
        <b-form-input
          id="input-img"
          v-model="wheel_item.img"
          placeholder="ลิ้งค์รูปภาพ"
        />
      </b-form-group>
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="ชื่อรางวัล:"
        label-for="input-detail"
      >
        <b-form-input
          id="input-detail"
          v-model="wheel_item.detail"
          placeholder="ชื่อรางวัล"
        />
      </b-form-group>
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="ยอดเงินรางวัล:"
        label-for="input-credit"
      >
        <b-form-input
          id="input-credit"
          v-model="wheel_item.credit"
          placeholder="ยอดเงินรางวัล"
          type="number"
        />
      </b-form-group>
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="โอกาสถูก(%):"
        label-for="input-percent"
      >
        <b-form-input
          id="input-percent"
          v-model="wheel_item.percent"
          placeholder="โอกาสถูก(%)"
          type="number"
        />
      </b-form-group>
      <b-form-group
        label-cols="4"
        label-cols-lg="4"
        label="อนุญาตออกรางวัล:"
        label-for="input-type"
      >
        <b-button 
          variant="btn-primary" 
          :class="{ 'bg-gradient-success': wheel_item.type == '1', 'bg-gradient-danger': wheel_item.type != '1' }" 
          class="btn waves-effect waves-light" 
          style="width: 120px"
          @click="toggleType()"
        >
          {{ wheel_item.type == '1' ? 'อนุญาต' : 'ไม่อนุญาต' }}
        </b-button>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCol, BRow, BTable, BInputGroup, BInputGroupPrepend, BFormInput, BButton, BFormGroup, BInputGroupAppend, BFormSelect,
  BPagination, BDropdown, BDropdownItem, BDropdownDivider,
  BMedia, BMediaAside, BAvatar,
} from 'bootstrap-vue'
import { BModal, VBModal, BAlert, BFormCheckbox, BSidebar, VBToggle  } from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import func from '@core/comp-functions/misc/func'
import {
  required, email, confirmed, password,
} from '@validations'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCol,
    BRow,
    BTable,
    BFormGroup,
    BFormSelect,
    BInputGroup, BInputGroupPrepend, BFormInput, BInputGroupAppend,
    BButton, BModal, BAlert, BFormCheckbox,
    BDropdown, BDropdownItem, BDropdownDivider,
    BMedia, BMediaAside, BAvatar, BPagination,
    BSidebar,
    vSelect,
    flatPickr,
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      func: func,
      BASE_URL: process.env.BASE_URL,
      required,
      confirmed,
      option: [
        { text: 'ปิดใช้งาน', value: '0' },
        { text: 'เปิดใช้งาน', value: '1' },
      ],
      option_register: [
        { text: 'หลังฝากเงินเข้าระบบครั้งแรก', value: '0' },
        { text: 'หลังสมัครสมาชิกเสร็จ', value: '1' },
      ],
      result: {
        id: 0,
        web_name: '',
        line_id: '',
        line_url: '',
        web_logo: '',
        telephone_number: '',
        web_title: '',
        web_description: '',
        web_keyword: '',
        clear_turn: 0,
        calculate_time: '11:00',
        withdraw_min_amount: 0,
        withdraw_verify_status: 0,
        withdraw_verify_time: '00:00',
        withdraw_member_status: 0,
        withdraw_member_status_text: '',
        discord_webhook_status: 0,
        discord_webhook_token: '',
        line_notify_status: 0,
        line_notify_token: '',
        line_notify_log_api_status: 0,
        line_notify_log_api_token: '',
        auto_create_member: 0,
        auto_create_member_status: 0,
        auto_create_member_status_text: '',
        auto_create_member_deposit_amount: 0,
        wheel_status: 0,
        wheel_banner: '',
        wheel_min_amount: '',
        wheelItems: [],
        reward_status: 0,
      },
      fields: [
        { key: 'num', label: 'ช่องที่', class: 'text-center' },
        { key: 'detail', label: 'ชื่อรางวัล', class: 'text-center text-nowrap' },
        { key: 'credit', label: 'ยอดเงินรางวัล', class: 'text-center' },
        { key: 'percent', label: 'โอกาสถูก(%)', class: 'text-center' },
        { key: 'type', label: 'อนุญาตออกรางวัล', class: 'text-center text-nowrap' },
        { key: 'setting', label: 'ตั้งค่าวงล้อ', class: 'text-center' },
      ],
      items: [],
      wheel_item: {
        detail: '',
        credit: 0,
        percent: 0,
        type: 0,
        img: '',
      },
      reward_item: {},
      date_start_report: '',
      date_end_report: '',
      draw: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      totalRows: 50,
      currentPage: 5,
      recordsTotal: 0,
      filter: '',
      filterOn: [],
      fields_reward: [
        { key: 'num', label: 'ลำดับ', class: 'text-center' },
        { key: 'image_url', label: 'รูปภาพ', class: 'text-center' },
        { key: 'name', label: 'ชื่อสินค้า', class: 'text-center' },
        { key: 'category_name', label: 'หมวดหมู่', class: 'text-center text-nowrap' },
        { key: 'price', label: 'ราคาสินค้า', class: 'text-center text-nowrap' },
        { key: 'item_qty', label: 'จำนวนสินค้า', class: 'text-center' },
        { key: 'point', label: 'ราคาแต้ม', class: 'text-center text-nowrap' },
        { key: 'manage', label: 'จัดการสินค้า', class: 'text-center' },
      ],
      items_reward: [],
      bank_list: JSON.parse('{"01":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","02":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","03":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","04":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","05":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","06":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","07":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","08":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","09":"\u0e18.\u0e01.\u0e2a.","1":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","2":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","3":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","4":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","5":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","6":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","7":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","8":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","9":"\u0e18.\u0e01.\u0e2a."}'),
    }
  },
  mounted() {
    this.fetch()
    this.totalRows = this.items.length
    this.fetch_reward()
  },
  watch: {
    filter: {
      handler() {
        this.fetch_reward()
        this.totalRows = this.items.length
        this.currentPage = 1
      },
    }
  },
  methods: {
    toMoney(value) {
      const parts = parseFloat(value).toFixed(2).split('.')
      return parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + (parts[1] ? `.${parts[1]}` : '')
    },
    modal_wheel(item) {
      this.wheel_item = item
    },
    modal_reward(item) {
      this.reward_item = item
    },
    toggleType() {
      if (this.wheel_item.type != 1)
        this.wheel_item.type = 1
      else
        this.wheel_item.type = 0
    },
    toggleRewardType() {
      if (this.reward_item.prefix != 'ADMIN')
        this.reward_item.prefix = 'ADMIN'
      else
        this.reward_item.prefix = ''
    },
    toggleMessage(text, msg) {
      if (text == 'auto_create_member_status_text') {
        this.result.auto_create_member_status_text = this.replaceMessage(this.result.auto_create_member_status_text, msg);
      } else if (text == 'withdraw_member_status_text') {
        this.result.withdraw_member_status_text = this.replaceMessage(this.result.withdraw_member_status_text, msg);
      }
    },
    replaceMessage(text, msg) {
      if (text.includes(msg)) {
        text = text.replace(`${msg}`, '');
      } else {
        text += `${msg}`;
      }
      return text;
    },
    onFiltered(filteredItems) {
      // this.totalRows = filteredItems.length
      // this.currentPage = 1
    },
    onPageSelect() {
      let start = this.perPage * (this.currentPage - 1)
      this.fetch_reward(start)
    },
    onPageChange(page) {
      let start = this.perPage * (page - 1)
      this.fetch_reward(start)
    },
    loading() {
      this.$swal({
			  text: "กรุณารอสักครู่..",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: '',
        willOpen: () => {
          this.$swal.showLoading()
        },
      });
    },
    success() {
      this.$swal({
        title: 'แจ้งเตือน',
        text: 'แก้ไขสำเร็จแล้ว!',
        icon: 'success',
        confirmButtonText: 'เรียบร้อย',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    load(data) {
      if (data == null || data.data == null) return;
      this.draw = data.draw
      this.items_reward = data.data
      this.totalRows = data.recordsFiltered
      this.recordsTotal = data.recordsTotal
    },
    fetch_reward(start=0) {
      let url = `setting/web_settings/itemreward_list_page?draw=${this.draw}&start=${start}&length=${this.perPage}&search%5Bvalue%5D=${this.filter}&date_start=${this.date_start_report}&date_end=${this.date_end_report}&status=&_=${Date.now()}`;
      this.$http.get(url).then(res => { this.load(res.data) })
    },
    fetch() {
      this.$http.get('setting/web_settings').then(res => {
        if (res.data.code != null && res.data.code === 0) {
          Object.assign(this.result, res.data.result);
          this.items = res.data.result.wheelItems
        }
      })
    },
    update() {
      this.loading()
      this.$http.post('setting/web_setting_update', this.result).then(res => {
        this.$swal.close()
        this.success()
      })
    },
    updateReward() {
      this.loading()
      this.$http.post('setting/reward/update', this.reward_item).then(res => {
        this.$swal.close()
        this.success()
      })
    },
    deleteReward(item) {
      this.loading()
      this.$http.post('setting/reward/delete', item).then(res => {
        this.$swal.close()
        this.success()
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>

